<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <ProgressSpinner />
  </div>
  <div v-else>
    <div :hidden="true">
      {{ updateKey }}
    </div>
    <SumHeaderTable
      :value="campaigns"
      :global-filter-fields="['campaignName']"
      :columns="columns"
      :sums="total"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { markRaw, defineAsyncComponent } from 'vue';
import { mergeApiCampaignsWithReports } from '../../../utils/reports/campaignReportUtils';
import generateCommonColumns from '../../../utils/tableUtils';
import SumHeaderTable from '../../SumHeaderTable.vue';

export default {
  components: { SumHeaderTable },
  props: {
    reports: {
      type: Object,
      required: true,
    },
    apiCampaigns: {
      type: Array,
      required: true,
    },
    total: {
      type: Object, required: true,
    },
  },
  data() {
    return {
      loading: false,
      updateKey: 0,
      campaigns: null,

      filteredCampaigns: null,
      columns: [
        {
          id: 'campaignName',
          field: 'campaignName',
          header: 'Name',
          sortable: true,
          numeric: false,
          decimal: false,
          width: '300px',
          content: {
            center: false,
            to: null,
            badge: null,
            format: null,
            slot: {
              component: markRaw(defineAsyncComponent(() => import('./campaign/CampaignStateWarning.vue'))),
              props: (data) => ({
                profileMetricsSetted: data.profileMetricsSetted,
                sales: data.sales14d,
                kenpRoyalties: data.kindleEditionNormalizedPagesRoyalties14d,
                campaignState: data.campaignStatus,
                campaignId: data.campaignId,
                campaignName: data.campaignName,
              }),
            },
          },
        },
        {
          id: 'campaignStatus',
          field: 'campaignStatus',
          header: 'Status',
          sortable: true,
          numeric: false,
          decimal: false,
          width: '100px',
          content: {
            center: false,
            to: null,
            badge: true,
            format: null,
          },
        },
        ...generateCommonColumns('campaigns', () => this.royalties, () => this.$store.state.auth.selectedProfile),
      ],
    };
  },
  computed: {
    ...mapGetters(['royalties']),
  },
  created() {
    this.loading = true;

    const mergedCampaigns = mergeApiCampaignsWithReports(this.reports, this.apiCampaigns);

    this.campaigns = mergedCampaigns;
    this.filteredCampaigns = this.campaigns.slice(0, 10);

    this.loading = false;
  },
};
</script>
