import { markRaw, defineAsyncComponent } from 'vue';
import {
  formatCost, formatSales, formatRoyalties, formatCpc, formatCtr, currencySymbolFromCode, formatKenpRoyalties, formatTrueAcos, formatAcos, formatAdjustedRevenue,
} from './formatUtils';
import COLUMN_TOTAL_AGGREGATION from '../constants/tableConstants';

/**
 * Generates the common columns of the reports tables
 * @param {String} idPrefix - Prefix of the column IDs
 *  @param {Number} royalties - Royalties used to calculate some column values
 *  @param {Object} profile - Current account profile
 *  @return {Array} generated common columns
 */
const generateCommonColumns = (idPrefix, royalties, profile) => {
  const currencySymbol = currencySymbolFromCode(profile().currencyCode);
  return [
    {
      id: `${idPrefix}Impressions`,
      field: 'impressions',
      header: 'Impressions',
      sortable: true,
      numeric: true,
      decimal: false,
      width: '130px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: null,
      },
    },
    {
      id: `${idPrefix}Clicks`,
      field: 'clicks',
      header: 'Clicks',
      sortable: true,
      numeric: true,
      decimal: false,
      width: '100px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: null,
      },
    },
    {
      id: `${idPrefix}Ctr`,
      field: 'ctr',
      header: 'CTR',
      sortable: true,
      numeric: true,
      decimal: true,
      width: '100px',
      content: {
        center: true,
        to: null,
        remove: '%',
        badge: null,
        format: (data) => (formatCtr(data)),
      },
      aggregation: COLUMN_TOTAL_AGGREGATION.CTR,
    },
    {
      id: `${idPrefix}Cost`,
      field: 'cost',
      header: 'Spend',
      sortable: true,
      numeric: true,
      decimal: true,
      width: '100px',
      content: {
        center: true,
        to: null,
        remove: currencySymbol,
        badge: null,
        format: (data) => (formatCost(data, currencySymbol)),
      },
    },
    {
      id: `${idPrefix}Cpc`,
      field: 'cpc',
      header: 'CPC',
      sortable: true,
      numeric: true,
      decimal: true,
      width: '100px',
      content: {
        center: true,
        to: null,
        remove: currencySymbol,
        badge: null,
        format: (data) => (formatCpc(data, currencySymbol)),
      },
      aggregation: COLUMN_TOTAL_AGGREGATION.CPC,
    },
    {
      id: `${idPrefix}Orders`,
      field: 'purchases14d',
      header: 'Orders',
      sortable: true,
      numeric: true,
      decimal: false,
      width: '100px',
      content: {
        center: true,
        to: null,
        badge: null,
        format: null,
      },
    },
    {
      id: `${idPrefix}Sales`,
      field: 'sales14d',
      header: 'Sales',
      sortable: true,
      numeric: true,
      decimal: true,
      width: '100px',
      content: {
        center: true,
        to: null,
        remove: currencySymbol,
        badge: null,
        format: (data) => (formatSales(data, currencySymbol)),
      },
    },
    {
      id: `${idPrefix}RegularAcos`,
      field: 'acos',
      header: 'ACOS',
      sortable: true,
      numeric: true,
      decimal: false,
      width: '170px',
      content: {
        center: true,
        to: null,
        remove: '%',
        badge: null,
        format: (data) => (formatAcos(data)),
      },
      aggregation: COLUMN_TOTAL_AGGREGATION.SUM,
    },
    {
      id: `${idPrefix}Royalties`,
      field: 'royalties',
      header: markRaw(defineAsyncComponent(() => import('../components/dashboard/table/EstimatedSalesRoyaltiesHeader.vue'))),
      sortable: true,
      numeric: true,
      decimal: true,
      width: '240px',
      content: {
        center: true,
        to: null,
        remove: currencySymbol,
        badge: null,
        format: (data) => (formatRoyalties(data, currencySymbol)),
      },
    },
    {
      id: `${idPrefix}KenpRoyalties`,
      field: 'kindleEditionNormalizedPagesRoyalties14d',
      header: 'KENP Royalties',
      sortable: true,
      numeric: true,
      decimal: true,
      width: '150px',
      content: {
        center: true,
        to: null,
        remove: currencySymbol,
        badge: null,
        format: (data) => (formatKenpRoyalties(data, currencySymbol)),
      },
    },
    {
      id: `${idPrefix}AdjustedRevenue`,
      field: 'adjustedRevenue',
      header: markRaw(defineAsyncComponent(() => import('../components/dashboard/table/AdjustedRevenueHeader.vue'))),
      sortable: true,
      numeric: true,
      decimal: true,
      width: '180px',
      content: {
        center: true,
        to: null,
        remove: currencySymbol,
        badge: null,
        format: (data) => (formatAdjustedRevenue(data, currencySymbol)),
      },
      aggregation: COLUMN_TOTAL_AGGREGATION.TRUE_ACOS,
    },
    {
      id: `${idPrefix}Acos`,
      field: 'trueAcos',
      header: markRaw(defineAsyncComponent(() => import('../components/dashboard/table/EstimatedAcorHeader.vue'))),
      sortable: true,
      numeric: true,
      decimal: false,
      width: '180px',
      content: {
        center: true,
        to: null,
        remove: '%',
        badge: null,
        format: (data) => (formatTrueAcos(data)),
      },
      aggregation: COLUMN_TOTAL_AGGREGATION.TRUE_ACOS,
    },
  ];
};

export default generateCommonColumns;
